import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: {
        name: 'Index'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue'),
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('../views/components/index.vue')
      },
      {
        path: 'demand',
        name: 'Demand',
        component: () => import('../views/components/demand.vue')
      },
      {
        path: 'shop',
        name: 'Shop',
        component: () => import('../views/components/shop.vue')
      },
      {
        path: 'case',
        name: 'Case',
        component: () => import('../views/components/case.vue')
      },
      {
        path: 'service',
        name: 'Service',
        component: () => import('../views/components/service.vue')
      }
    ]
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('../views/agreement.vue')
  },
  {
    path: '/details/:id',
    name: 'Details',
    component: () => import('../views/components/details.vue')
  },
  {
    path: '/demandDetails/:id',
    name: 'DemandDetails',
    component: () => import('../views/components/demandDetails.vue')
  },
  {
    path: '/shopDetails/:id',
    name: 'ShopDetails',
    component: () => import('../views/components/shopDetails.vue')
  },
  {
    path: '/submitMessage',
    name: 'SubmitMessage',
    component: () => import('../views/components/submitMessage.vue')
  },
  {
    path: '/editMessage',
    name: 'EditMessage',
    component: () => import('../views/components/submitMessage.vue')
  },
  {
    path: '/myOrder',
    name: 'MyOrder',
    component: () => import('../views/components/myOrder.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router